import React from "react";

export default ({ image, name, title, description }) => {
    return (
    <div class="column is-3">
      <div class="card">
        <div class="card-image">
            <figure class="image is-4by3">
            <img src={image} alt={name} />
            </figure>
        </div>
        <div class="card-content">
            <div class="content">
            <p class="title is-4">{name}</p>
            <p class="subtitle is-6">{title}</p>
            <p>{description}</p>
            </div>
        </div>
      </div>
    </div>
)};
