import React from "react";
import Page from "../layouts/Page";
import TeamMember from "../components/TeamMember";
import _ from "lodash";
import Icon from '../components/Icon';
import network from '../media/icons/network.svg';
import bank from '../media/icons/bank.svg';
import speed from '../media/icons/speed.svg';
import world from '../media/icons/world.svg';
import Build from '../media/illustration/Build.png';
import AnthonyPhoto from '../media/team/Anthony.jpeg'
import AndyPhoto from '../media/team/Andy.jpg'
import JorgePhoto from '../media/team/Jorge.jpg'
import HadrienPhoto from '../media/team/Hadrien.jpeg'
import AlexandrePhoto from '../media/team/Alexandre.jpg'
import JulesPhoto from '../media/team/Jules.jpeg'

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      layout: (typeof window !== "undefined" && window.LAYOUT) || "grid",
      title: "Découvrir Akimed",
      description: "Akimed est une société française rassemblant des experts dans le médical et les technologies utilisées dans la santé.",
    };
  }

  render() {
    return (
      <Page title={this.state.title} description={this.state.description}>
        <section class="section">
          <div class="container">
            <div class="columns">
              <div class="column">
                <h1 className="title">Découvrir Akimed</h1>
                <div class="content is-medium">
                  <p>Nous sommes une équipe <strong>pluridisciplinaire</strong> composée de passionnés qui souhaitent mettre leur énergie et leur savoir-faire au service de la santé.</p>
                  <p>Akimed rassemble des <strong>ingénieurs</strong>, des <strong>médecins</strong> et des <strong>consultants</strong> qui ont une connaissance approfondie des problématiques liés aux systèmes de santé. Nous avons fait de ce domaine notre cheval de bataille.</p>
                  <p>Akimed, c'est&nbsp;:</p>
                  <ul>
                    <li>plus de <strong>70 ans</strong> cumulés d’expérience dans le secteur de la santé&nbsp;;</li>
                    <li>plus de <strong>150 millions</strong> de dossiers patients transformés&nbsp;;</li>
                    <li>plus de <strong>10 projets internationaux</strong> en France, aux États-Unis, en Belgique et en Suisse&nbsp;;</li>
                    <li>des <strong>professionnels de santé</strong> reconnus dans les maladies auto-immunes et en hématologies.</li>
                  </ul>
                </div>
              </div>
              <div class="column is-narrow">
                <img src={Build} style={{ maxWidth: '500px' }} alt="Together further in health"/>
              </div>
            </div>
            </div>
          </section>
          <section class="alt-section">
            <div class="container">
              <h1 class="title is-size-3 has-text-centered">Découvrer les membres de notre équipe</h1>
              <div class="columns is-centered">
                <TeamMember image={AnthonyPhoto} name="Anthony Dubois" title="CEO / Expert data" description="Spécialiste des données de vie réelle et des standards d'intéropérabilité en santé OMOP et FHIR." />
                <TeamMember image={HadrienPhoto} name="Hadrien Chauvin" title="Technical Lead / Expert FHIR" description="Véritable expert du stanadard FHIR, de génomique et du développement logiciel, vous pouvez compter sur Hadrien pour passer d'une idée à du concret." />
                <TeamMember image={AlexandrePhoto} name="Alexandre Yazigi" title="Oncologue" description="Chirurgien oncologue, Alexandre est passionné par l'innovation et souhaite mettre ses connaissances au service des entreprises qui servent la recherche et les patients." />
              </div>
              <div class="columns is-centered">
                <TeamMember image={JorgePhoto} name="Jorge Arellano" title="Data scientist" description="Biologiste et spécialiste des données de vie réeelle, Jorge accompagne nos clients sur les transformations vers les standards et les études observationnelles." />
                <TeamMember image={AndyPhoto} name="Andy Pilate" title="Backend developer" description="Actif à tous les niveaux du développement, Andy se distingue par son expertise dans l'application des normes et services de l'ANS (Agence du Numérique en Santé), au profit de nos clients." />
                <TeamMember image={JulesPhoto} name="Jules Deschamps" title="Responsable commercial" description="Vrai entrepreneur, Jules n'en est pas à sa première aventure et accompagne les projets sur le déploiement de l'INS et du DMP." />
              </div>
            </div>
          </section>
          <section>
          <div class="container">
            <h2 className="title" style={{marginTop: "40px"}}>Notre approche pour une informatique médicale pérenne</h2>
            <div class="tile is-ancestor">
              <div class="tile is-vertical is-parent">
                <article className="tile is-child item">
                  <p class="item-title" style={{fontSize: "20px"}}>
                    <figure class="image is-64x64">
                      <Icon src={network} alt />
                    </figure>
                    <strong>Nous créons nos solutions avec l’utilisateur final</strong> pour que la technologie serve le besoin.
                  </p>
                </article>
                <article className="tile is-child item">
                  <p class="item-title" style={{fontSize: "20px"}}>
                    <figure class="image is-64x64">
                      <Icon src={bank} alt />
                    </figure>
                    <strong>Nous intégrons le contexte et les contraintes</strong> en faisant évoluer l’existant et en inscrivant la solution dans un écosystème adapté aux contraintes réglementaires.
                  </p>
                </article>
              </div>
              <div class="tile is-vertical is-parent">
                <article className="tile is-child item">
                  <p class="item-title" style={{fontSize: "20px"}}>
                    <figure class="image is-64x64">
                      <Icon src={speed}alt />
                    </figure>
                    <strong>Nous pérennisons et accélérons vos projets</strong> en construisant des briques logicielles réutilisables et en privilégiant le co-investissement.
                  </p>
                </article>
                <article className="tile is-child item">
                  <p class="item-title" style={{fontSize: "20px"}}>
                    <figure class="image is-64x64">
                      <Icon src={world} alt />
                    </figure>
                    <strong>Nous réutilisons les technologies open source et les standards</strong> déjà éprouvés pour favoriser la collaboration, mutualiser les coûts de maintenance et développer l’interopérabilité.
                  </p>
                </article>
              </div>
            </div>
          </div>
        </section>
      </Page>
    );
  }
}
